import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useLang } from "../../helpers/language";
import config from "../../config";
import * as API from "../../helpers/api";
import { SmallSection } from "../section/section";
import JobsModule from "../modules/projects/projectsVertical";
import { Oval } from "react-loader-spinner";

export default function Project() {
  const [isBusy, setBusy] = useState(true);
  const id =
    useLocation().pathname.split("/")[
      useLocation().pathname.split("/").length - 1
    ];
  const { lang } = useLang();
  const [service, setservice] = useState({});
  const [image, setImage] = useState({});

  useEffect(() => {
    (async () => {
      let getRawData = await API.get(config.api.projects, { _id: id });
      let image = await API.get(config.api.upload, {
        type: { $regex: ".*image.*" },
        obj_id: id,
      });
      setservice(getRawData[0]);
      setImage(image[0]);
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Oval color="#04b4e0" className="loader" />;
  else
    return (
      <div className="projects">
        <div className="section full-width section-small">
          <div className={"page-top-img"}>
            <img
              src={`${
                image
                  ? image.path
                    ? config.api.API_URL + "/" + image.path
                    : `${config.api.MAIN_APP_URL}/default-banner.jpg`
                  : `${config.api.MAIN_APP_URL}/default-banner.jpg`
              }
							`}
              alt="top"
            />
          </div>
          <div className="col-12 page-title">
            <h1>
              <Link to={"/projects"}>{config.lang.menu.projects[lang]}</Link>
              {` / ${
                lang === "en"
                  ? service.name.en
                  : lang === "ru"
                  ? service.name.ru
                  : lang === "hy"
                  ? service.name.hy
                  : ""
              }`}
            </h1>
          </div>
        </div>
        <div className="section section-big">
          <SmallSection component={JobsModule} className="min-margin" />
        </div>
      </div>
    );
}
