import React, { useState, useEffect } from "react";
import { SmallSection } from "../section/section";
import NewsModule from "../modules/news/newsVertical";
import config from "../../config";
import { useLang } from "../../helpers/language";
import { Oval } from "react-loader-spinner";

export default function News() {
  const [isBusy, setBusy] = useState(true);
  let { lang } = useLang();

  useEffect(() => {
    (async () => {
      setBusy(false);
    })();
  }, [isBusy]);

  if (isBusy) return <Oval color="#04b4e0" className="loader" />;
  else
    return (
      <>
        <div className="news-list">
          <div className="section full-width section-small">
            <div className="page-top-img">
              <img
                src={`${config.api.MAIN_APP_URL}/default-banner.jpg`}
                alt="top"
              />
            </div>
            <div className="col-12 page-title">
              <h1>{config.lang.menu.news[lang]}</h1>
            </div>
          </div>
          <SmallSection component={NewsModule} className="min-margin" />
        </div>
      </>
    );
}
