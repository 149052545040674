import React, { useState, useEffect } from "react";
import AnimatedNumber from "animated-number-react";
import config from "../../../config";
import { useLang } from "../../../helpers/language";
import * as API from "../../../../src/helpers/api";
import { Oval } from "react-loader-spinner";

import developmentIcon from "./icons/development.png";
import briefingIcon from "./icons/briefing.png";
import projectIcon from "./icons/project.png";

function AnimatedNumbers() {
  let { lang } = useLang();
  const [isBusy, setBusy] = useState(true);

  const [counter1, setCounter1] = useState(0);
  const [counter2, setCounter2] = useState(0);
  const [counter3, setCounter3] = useState(0);

  useEffect(() => {
    (async () => {
      let rowData = await API.get(config.api.animatedNumbers);
      setCounter1(rowData[0].counter1);
      setCounter2(rowData[0].counter2);
      setCounter3(rowData[0].counter3);
      setBusy(false);
    })();
  }, [isBusy]);

  if (isBusy) return <Oval color="#04b4e0" className="loader" />;
  else
    return (
      <div className="animated-counter container">
        <div className="row">
          <div className="animated-counter-section col-12 col-md-4">
            <div className="row text-center">
              <div className="col-md-4">
                <img src={briefingIcon} alt="" />
              </div>
              <div className="col-md-8">
                <AnimatedNumber
                  value={counter1}
                  formatValue={(value) => value.toFixed(2)}
                  duration="2000"
                />
                <p>{config.lang.homePage.numbers[0][lang]}</p>
              </div>
            </div>
          </div>
          <div className="animated-counter-section col-md-4">
            <div className="row text-center">
              <div className="col-md-4">
                <img src={projectIcon} alt="" />
              </div>
              <div className="col-md-8">
                <AnimatedNumber
                  value={counter2}
                  formatValue={(value) => value.toFixed(2)}
                  duration="2000"
                />
                <p>{config.lang.homePage.numbers[1][lang]}</p>
              </div>
            </div>
          </div>
          <div className="animated-counter-section col-md-4">
            <div className="row text-center">
              <div className="col-md-4">
                <img src={developmentIcon} alt="" />
              </div>
              <div className="col-md-8">
                <AnimatedNumber
                  value={counter3}
                  formatValue={(value) => value.toFixed(2)}
                  duration="2000"
                />
                <p>{config.lang.homePage.numbers[2][lang]}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default AnimatedNumbers;
