import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import config from '../../../config'
import { useLang } from '../../../helpers/language';
function CTA() {
    let { lang } = useLang();

    return (
        <div className="cta">
            <div className="container">
                <div className="row">
                    <div className="col-xl-9 col-lg-8 content-left">
                        <h3>{config.lang.homePage.cta.main[lang]}</h3>
                    </div>
                    <div className="col-xl-3 col-lg-4 content-right">
                        <a className="item-btn" href="tel:+010511188">
                            <span>
                            {config.lang.homePage.cta.button[lang]}
                                <FontAwesomeIcon icon="phone-volume" />
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CTA