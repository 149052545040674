import React, { useState, useEffect } from "react";
import config from "../../../config";
import * as API from "../../../helpers/api";
import { Oval } from 'react-loader-spinner';
import { useLang } from "../../../helpers/language";
import parse from "html-react-parser";
// Import Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper/core";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

import './ourOpinion.scss'

function OurOpinion() {
  const [isBusy, setBusy] = useState(true);
  let { lang } = useLang();
  let [opinion, setopinion] = useState();
  let [screenWidth] = useState(window.innerWidth.toString());
  let slidesPerView = 1;
  //eslint-disable-next-line
  let slidesPerViewLogos = 1;
  if (screenWidth >= 500) {
    slidesPerView = 2;
    slidesPerViewLogos = 2;
  }

  if (screenWidth >= 900) {
    slidesPerView = 3;
    slidesPerViewLogos = 4;
  }
  SwiperCore.use([Navigation]);
  SwiperCore.use([Pagination]);
  SwiperCore.use([Autoplay]);


  useEffect(() => {
    (async () => {
      let rowData = await API.get(config.api.opinion, {}, {}, true);

      // for (let i = 0; i < rowData.length; i++) {
      //   let data = rowData;
      //   let image = await API.getImagesByObjName(data);
      //   data.imageUrl = `${config.api.API_URL}/${image[0] && image[0].path}`;
      //   initialArray.push(data);
      // }

      setopinion(rowData);

      setBusy(false);
    })();
  }, [isBusy]);
  if (isBusy) return <Oval color="#04b4e0" className="loader" />;
  else
    return (

      <div className="opinion position-relative">
        <h2 className="section-title" style={{ marginBottom: 50, color: '#fff' }}>
          {config.lang.homePage.ourOption.title[lang]}
        </h2>
        <div className="swiper-button-next news-arrow-next"></div>
        <Swiper
          spaceBetween={0}
          slidesPerView={slidesPerView}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          props={{
            observer: true,
            observeParents: true,
            updateOnWindowResize: true,
          }}
          observer={true}
          observeParents={true}
          updateOnWindowResize={() => {
            console.log(444);
          }}
          pagination={false}
        // pagination={{ clickable: true }}
        // scrollbar={{ draggable: true }}
        >
          {opinion.map((item, index) => (
            <SwiperSlide key={index} className="d-flex justify-content-center">
              <div key={index} style={{ outline: "none" }} className="opinion-item">
                <div className="opinion-item-img">
                  <img src={item?.uploads ? `${config.api.API_URL}/${item?.uploads && item?.uploads[0]?.path
                    }` : null} alt="clients" />
                </div>
                <div className="opinion-item-description">
                {lang === "en"
              ? parse(item.desc?.en ? item.desc?.en : "")
              : lang === "ru"
              ? parse(item.desc?.ru ? item.desc?.ru : "")
              : lang === "hy"
              ? parse(item.desc?.hy ? item.desc?.hy : "")
              : ""}
                </div>
                <hr className="opinion-item-line"/>
                <div className="opinion-item-author">
                  <h4 className="opinion-item-author-title">
                  <span className="mr-1">{lang === "en"
                      ? item?.lastName?.en?.length > 42
                        ? item?.lastName?.en.slice(0, 42) + "..."
                        : item?.lastName?.en
                      : lang === "ru"
                      ? item?.lastName?.ru?.length > 42
                        ? item?.lastName?.ru?.slice(0, 42) + "..."
                        : item?.lastName?.ru
                      : lang === "hy"
                      ? item?.lastName?.hy?.length > 42
                        ? item?.lastName?.hy?.slice(0, 42) + "..."
                        : item?.lastName?.hy
                      : null}
                      </span>
                      <span>
                      {lang === "en"
                      ? item?.firstName?.en?.length > 42
                        ? item?.firstName?.en.slice(0, 42) + "..."
                        : item?.firstName?.en
                      : lang === "ru"
                      ? item?.firstName?.ru?.length > 42
                        ? item?.firstName?.ru?.slice(0, 42) + "..."
                        : item?.firstName?.ru
                      : lang === "hy"
                      ? item?.firstName?.hy?.length > 42
                        ? item?.firstName?.hy?.slice(0, 42) + "..."
                        : item?.firstName?.hy
                      : null}
                      </span>
                  </h4>
                  <span className="opinion-item-author-position">
                  <span className="mr-1">
                    {item?.company}:
                  </span>
                  <span>
                    {item?.position}
                  </span>
                  </span>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-button-prev news-arrow-prev"></div>
      </div>
    );
}

export default OurOpinion;
