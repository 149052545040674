import React, { useState, useEffect } from 'react';
import config from '../../config';
import * as API from '../../helpers/api';
import { useLang } from '../../helpers/language';
import { useLocation, Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { Oval } from 'react-loader-spinner';
// Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

export default function PortfolioList() {
  const [isBusy, setBusy] = useState(true);
  // eslint-disable-next-line
  const [portfolio, setportfolio] = useState(true);
  const [images, setImages] = useState([]);
  const [slider, setSlider] = useState({});
  const { lang } = useLang();
  let [screenWidth] = useState(window.innerWidth.toString());
  let slidesPerView = 1;
  // eslint-disable-next-line
  let slidesPerViewLogos = 1;
  if (screenWidth >= 500) {
    slidesPerView = 2;
    slidesPerViewLogos = 2;
  }

  if (screenWidth >= 900) {
    slidesPerView = 3;
    slidesPerViewLogos = 4;
  }

  if (screenWidth >= 1300) {
    slidesPerView = 4;
    slidesPerViewLogos = 6;
  }
  const id =
    useLocation().pathname.split('/')[
      useLocation().pathname.split('/').length - 1
    ];
  SwiperCore.use([Navigation]);
  SwiperCore.use([Pagination]);
  SwiperCore.use([Autoplay]);

  useEffect(() => {
    (async () => {
      let getRawData = await API.get(
        config.api.portfolio,
        {
          _id: id,
        },
        {},
        {},
        { destiny: 'image' }
      );
      setportfolio(getRawData[0]);
      if (getRawData[0]?.slider_id) {
        let getSlider = await API.get(
          config.api.slider,
          {
            _id: getRawData[0]?.slider_id,
          },
          {},
          true
        );
        setSlider(getSlider[0]);
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  console.log(slider.uploads);

  if (isBusy) return <Oval color='#04b4e0' className='loader' />;
  else
    return (
      <div className='news'>
        <div className='section full-width section-small'>
          <div className={'page-top-img'}>
            <img
              src={`url(${config.api.API_URL}/${
                portfolio
                  ? portfolio.uploads
                    ? portfolio.uploads[0]
                      ? portfolio.uploads[0].path
                      : ''
                    : ''
                  : ''
              })`}
              alt='top'
            />
          </div>
          <div className='col-12 page-title'>
            <h1>
              <Link to='/news'>{config.lang.menu.portfolio[lang]}</Link>
              {` / ${
                lang === 'en'
                  ? portfolio.name.en
                  : lang === 'ru'
                  ? portfolio.name.ru
                  : lang === 'hy'
                  ? portfolio.name.hy
                  : ''
              }`}
            </h1>
          </div>
        </div>
        <div className='section section-big'>
          <div className='single-page-container'>
            {lang === 'en'
              ? parse(portfolio.desc.en ? portfolio.desc.en : '')
              : lang === 'ru'
              ? parse(portfolio.desc.ru ? portfolio.desc.ru : '')
              : lang === 'hy'
              ? parse(portfolio.desc.hy ? portfolio.desc.hy : '')
              : ''}
          </div>
          {slider.uploads && (
            <div className='single-page-container'>
              <Swiper
                className='position-static'
                spaceBetween={10}
                slidesPerView={slidesPerView}
                navigation
                observer={true}
                observeParents={true}
                pagination={{ clickable: true }}
              >
                {slider.uploads.map((item, index) => (
                  <SwiperSlide className='position-relative'>
                    <div className='news-slider'>
                      <div className='news-slider-img'>
                        <img
                          src={`${config.api.API_URL}/${item?.path}`}
                          alt=''
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>
      </div>
    );
}
