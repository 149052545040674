import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Pagination from "../pagination";
import config from "../../../config";
import * as API from "../../../../src/helpers/api";
import { useLang } from "../../../helpers/language";
import parse from "html-react-parser";
import { useLocation } from "react-router-dom";

const Items = ({ items, loading }) => {
  let { lang } = useLang();

  if (loading) {
    return <h2>Loading...</h2>;
  }

  return items.map((item, key) => (
    <div className="our-projects row" key={key}>
      <div className="our-projects-img col-md-4">
        <img
          src={`${config.api.API_URL}/${
            item
              ? item.uploads
                ? item.uploads[0]
                  ? item.uploads[0].path
                  : ""
                : ""
              : ""
          }`}
          alt=""
        />
      </div>
      <div className="our-projects-content col-md-8">
        <h3>
          <Link to={`/portfolio/${item._id}`}>
            {lang === "en"
              ? item.name.en
              : lang === "ru"
              ? item.name.ru
              : lang === "hy"
              ? item.name.hy
              : null}
          </Link>
        </h3>
        <span>
          {lang === "en"
            ? parse(item.desc?.en ? item.desc?.en.slice(0, 100) + "..." : "")
            : lang === "ru"
            ? parse(item.desc?.ru ? item.desc?.ru.slice(0, 100) + "..." : "")
            : lang === "hy"
            ? parse(item.desc?.hy ? item.desc?.hy.slice(0, 100) + "..." : "")
            : ""}
        </span>
      </div>
    </div>
  ));
};

function Projects() {
  const [projects, setprojects] = useState([]);
  const id =
    useLocation().pathname.split("/")[
      useLocation().pathname.split("/").length - 1
    ];
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(5);

  useEffect(() => {
    (async () => {
      let rowData = await API.get(
        config.api.portfolio,
        { project_id: id },
        {},
        true
      );
      setprojects(rowData);

      setLoading(false);
    })();
  }, [id]);

  // Get current items
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentOnes = projects.slice(indexOfFirst, indexOfLast);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="projects-vertical">
      <div className="section-content">
        <Items items={currentOnes} loading={loading} />
        <Pagination
          perPage={perPage}
          totalItems={projects.length}
          paginate={paginate}
        />
      </div>
    </div>
  );
}

export default Projects;
