import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import config from "../../../config";
import * as API from "../../../helpers/api";
import { useLang } from "../../../helpers/language";
import Pagination from "../pagination";
import parse from "html-react-parser";
const Items = ({ items, loading }) => {
  let { lang } = useLang();

  if (loading) {
    return <h2>Loading...</h2>;
  }

  return(  
        items.map((item, key) => {return (
          <div
                className="col-lg-3 col-md-6 col-sm-6 col-12 item-wrapper p-0 mb-4"
                key={key}
              >
                <div className="item">
                  <Link to={`/projects/${item._id}`}>
                    <div className="item-img">
                      <img src={item?.uploads ? `${config.api.API_URL}/${
                                item?.uploads && item?.uploads[0]?.path
                              }` : null} alt="" />
                    </div>
                    <div className="item-title">
                      <h5>
                        {lang === "en"
                          ? parse(
                              item.name.en
                                ? item.name.en.length > 42
                                  ? item.name.en.slice(0, 42) + "..."
                                  : item.name.en
                                : ""
                            )
                          : lang === "ru"
                          ? parse(
                              item.name.ru
                                ? item.name.ru.length > 42
                                  ? item.name.ru.slice(0, 42) + "..."
                                  : item.name.ru
                                : ""
                            )
                          : lang === "hy"
                          ? parse(
                              item.name.hy
                                ? item.name.hy.length > 42
                                  ? item.name.hy.slice(0, 42) + "..."
                                  : item.name.hy
                                : ""
                            )
                          : ""}
                      </h5>
                    </div>
                  </Link>
                </div>
              </div>
        )})
  )
};

function Projects(props) {
  let { lang } = useLang();
  const [projects, setprojects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(5);
  // Get current items
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentOnes = projects.slice(indexOfFirst, indexOfLast);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    (async () => {
      let rowData = await API.get(
        config.api.projects,
        {},
        {},
        true
      );

      // for (let i = 0; i < rowData.length; i++) {
      //   const data = rowData;
      //   let image = await API.getImagesByObjName(data);
      //   data.imageUrl = `${config.api.API_URL}/${image[0] && image[0].path}`;
      // }

      setprojects(rowData);
      setLoading(false);
    })();
  }, []);

  return (
    <div className="services">
      {props.noTitle ? null : (
        <>
          <h2 className="section-title">
            {config.lang.homePage.projects.title[lang]}
          </h2>
          {/* <p className="section-subtitle">
            {config.lang.homePage.projects.subtitle[lang]}
          </p> */}
        </>
      )}
      <div className="row section-content" style={{ position: "relative" }}>
        <Items items={currentOnes} loading={loading} />
        <Pagination
          dataPerPage={perPage}
          totalCount={projects.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
}

export default Projects;
