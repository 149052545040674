import React from 'react';
import { SmallSection } from '../section/section';
import ContactsModule from '../modules/contacts/contactsModule';
import { useLang } from '../../helpers/language';
import config from '../../config';

export default function Contacts() {
	const { lang } = useLang();

		return (
			<>
				<div className='contacts'>
					<div className='section full-width section-small'>
						<div className={'page-top-img'}>
							<img
								src={
									'https://radiustheme.com/demo/wordpress/themes/koncrete/wp-content/themes/koncrete/assets/img/default-banner.jpg'
								}
								alt='top'
								className={'page-top-img'}
							/>
						</div>
						<div className='col-12 page-title'>
							<h1>{config.lang.menu.contacts[lang]}</h1>
						</div>
					</div>
					<SmallSection
						component={ContactsModule}
						className='min-margin'
					/>
				</div>
			</>
		);
}
