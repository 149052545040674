import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from '../../../config';
import { useLang } from '../../../helpers/language';
import * as API from '../../../helpers/api';
import parse from 'html-react-parser';
import { Oval } from 'react-loader-spinner';

function Services() {
  let { lang } = useLang();
  const [isBusy, setBusy] = useState(true);
  const [services, setservices] = useState(true);

  useEffect(() => {
    (async () => {
      let rowData = await API.get(config.api.services, {}, { index: 1 }, true);
      setservices(rowData);

      setBusy(false);
    })();
  }, [isBusy]);

  if (isBusy) return <Oval color='#04b4e0' className='loader' />;
  else
    return (
      <div className='services'>
        <h2 className='section-title'>
          {config.lang.homePage.services.title[lang]}
        </h2>
        {/* <p className='section-subtitle'>
					{config.lang.homePage.services.subtitle[lang]}
				</p> */}
        <div className='section-content row'>
          {services.map((item, key) => {
            return (
              <div
                className='col-lg-3 col-md-6 col-sm-6 col-12 item-wrapper p-0 mb-1'
                key={key}
              >
                <div className='item'>
                  <Link to={`/services/${item._id}`}>
                    <div className='item-img'>
                      <img
                        src={
                          item?.uploads
                            ? `${config.api.API_URL}/${
                                item?.uploads && item?.uploads[0]?.path
                              }`
                            : null
                        }
                        alt=''
                      />
                    </div>
                    <div className='item-title'>
                      <h5>
                        {lang === 'en'
                          ? parse(
                              item.name.en
                                ? item.name.en.length > 42
                                  ? item.name.en.slice(0, 42) + '...'
                                  : item.name.en
                                : ''
                            )
                          : lang === 'ru'
                          ? parse(
                              item.name.ru
                                ? item.name.ru.length > 42
                                  ? item.name.ru.slice(0, 42) + '...'
                                  : item.name.ru
                                : ''
                            )
                          : lang === 'hy'
                          ? parse(
                              item.name.hy
                                ? item.name.hy.length > 42
                                  ? item.name.hy.slice(0, 42) + '...'
                                  : item.name.hy
                                : ''
                            )
                          : ''}
                      </h5>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
}

export default Services;
