import axios from "axios";
import { cookies } from "./cookies";
import config from "../config";
const API = axios.create({
  baseURL: `${config.api.API_URL}/${config.api.API_Version}/`,
});

export const settings = {
  onUploadProgress: function (progressEvent) {
    // eslint-disable-next-line
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
  },
  headers: {
    keyfile: cookies.get(config.authentification.tokenAddress),
  },
};

export function get(route, query, sort, uploadType) {
  let completeRoute = `${route}${query ? `?q=${JSON.stringify(query)}` : ""}${
    sort ? `&sort=${JSON.stringify(sort)}` : ""
  }${uploadType ? `&uploadType=${JSON.stringify(uploadType)}` : ""}`;
  return new Promise((resolve, reject) => {
    if (route && completeRoute && completeRoute.length !== 0) {
      API.get(completeRoute, settings)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
      // }
    }
  });
}
export function getById(route, id) {
  return new Promise((resolve, reject) => {
    API.get(`${route}/${id}`, settings)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}
export function post(route, data) {
  return new Promise((resolve, reject) => {
    API.post(route, data, settings)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}
export function update(route, id, data) {
  return new Promise((resolve, reject) => {
    API.put(`${route}/${id}`, data, settings)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}
export function remove(route, id) {
  return new Promise((resolve, reject) => {
    API.delete(`${route}/${id}`, settings)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}
export const upload = (id, files, lang, destiny) => {
  return Promise.all(
    files &&
      files.map((file, index) => {
        const formData = new FormData();
        id && formData.append("obj_id", id);
        file && formData.append("file", file);
        lang && formData.append("lang", lang);
        destiny && formData.append("destiny", destiny);
        return new Promise((resolve, reject) => {
          API.post(config.api.uploads, formData, settings)
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      })
  );
};

export function getImagesByObjName(obj) {
  return new Promise((resolve, reject) => {
    API.get(
      `${config.api.upload}?q=${JSON.stringify({
        type: { $regex: ".*image.*" },
        obj_id: obj._id,
      })}`
    )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

export default API;
