import React, { useState, useEffect } from "react";
import { Navigation } from "./listItems";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../../config";
import { useLang } from "../../helpers/language";

import MainLogo from "./main-logo.svg";
import clockIcon from "./icons/clock.svg";
import phoneIcon from "./icons/phone.svg";
export default function Navbar(props) {
  const location = useLocation();
  let [open, setOpen] = useState(false);
  let { lang, setlang } = useLang();
  let [showDropDown, setShowDropDown] = useState(false);
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? true : false;
  };
  const handleOpen = () => {
    setOpen(!open);
  };
  const dropDowntoggle = () => {
    setShowDropDown(!showDropDown);
  };
  const changeLanguage = (e) => {
    setlang(e.target.lang);
  };
  useEffect(() => {}, [open]);

  return (
    <div className="header-nav-bar d-block">
      <div className="desktop-nav nav">
        {/* <div className="row justify-content-between">
            <div className="logo">
              <Link to={"/"} className="temp-logo">
                <img
                  src="https://admin.volios.am/volios.png"
                  className="logo-img"
                  alt="navbar"
                />
                <span className="logo-text">Volios</span>
              </Link>
            </div>
            <div className="main-navigation-area">
              <div className="main-navigation" style={{ display: "block" }}>
                <nav>
                  <ul className="menu">
                    {Navigation().map((nav, index) => {
                      return (
                        <li key={index}>
                          <Link
                            className={
                              activeRoute(`/${nav.route}/`)
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to={`/${nav.route}/`}
                          >
                            {nav.name.charAt(0).toUpperCase() +
                              nav.name.slice(1)}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </nav>
              </div>
            </div>
          </div> */}
        <div className="row justify-content-around">
          <div className="col-1">
            <div className="dropdown">
              <button
                className="btn btn-white dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                onClick={dropDowntoggle}
              >
                {lang === "en" ? "Eng" : lang === "ru" ? "Рус" : "Հայ"}
              </button>
              <ul
                className={`dropdown-menu  ${showDropDown ? "show" : ""}`}
                aria-labelledby="dropdownMenuButton1"
              >
                {lang !== "en" ? (
                  <li onClick={dropDowntoggle}>
                    <a
                      className="dropdown-item"
                      href=""
                      onClick={changeLanguage}
                      lang="en"
                    >
                      Eng
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {lang !== "ru" ? (
                  <li onClick={dropDowntoggle}>
                    <a
                      className="dropdown-item"
                      href={() => false}
                      onClick={changeLanguage}
                      lang="ru"
                    >
                      Рус
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {lang !== "hy" ? (
                  <li onClick={dropDowntoggle}>
                    <a
                      className="dropdown-item"
                      href={() => false}
                      onClick={changeLanguage}
                      lang="hy"
                    >
                      Հայ
                    </a>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
          <div className="col-9">
            <div className="row p-0 justify-content-end">
              <div className="col-4">
                <ul className="nav justify-content-around flex-nowrap">
                  {Navigation().map((nav, index) => {
                    if (index < 3) {
                      return (
                        <li key={index}>
                          <Link
                            className={
                              activeRoute(`/${nav.route}/`)
                                ? "p-1 nav-link active"
                                : "p-1 nav-link"
                            }
                            to={`/${nav.route}/`}
                          >
                            {nav.name.charAt(0).toUpperCase() +
                              nav.name.slice(1)}
                          </Link>
                        </li>
                      );
                    } else return null;
                  })}
                </ul>
              </div>
              <div className="col-3 d-flex align-items-center">
                <div className="logo">
                  <Link to={"/"}>
                    <img
                      src={MainLogo}
                      className="logo-img-main"
                      alt="navbar"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-4">
                <ul className="nav justify-content-around flex-nowrap">
                  {Navigation().map((nav, index) => {
                    if (index > 3) {
                      return (
                        <li key={index}>
                          <Link
                            className={
                              activeRoute(`/${nav.route}/`)
                                ? "p-1 nav-link active"
                                : "p-1 nav-link"
                            }
                            to={`/${nav.route}/`}
                          >
                            {nav.name.charAt(0).toUpperCase() +
                              nav.name.slice(1)}
                          </Link>
                        </li>
                      );
                    } else return null;
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-2 contact-info d-flex flex-column justify-content-center">
            <span>
              <img src={clockIcon} alt="" className="mr-2" />
              {lang === "en"
                ? "Mon - Fri"
                : lang === "ru"
                ? "Пон - Пят"
                : "Երկ - Ուրբ"}{" "}
              09:00-18:00
            </span>
            <span>
              <a href="tel:+37410511188">
                <img src={phoneIcon} alt="" className="mr-2" />
                <b>+37410511188</b>
              </a>
            </span>
            <span>
              <a href="tel:+37410511191">
                <img src={phoneIcon} alt="" className="mr-2" />
                <b>+37410511191</b>
              </a>
            </span>
          </div>
        </div>
      </div>
      <div className="mobile-nav nav">
        <FontAwesomeIcon
          icon="bars"
          className="hamburger-icon"
          onClick={handleOpen}
        />
        <div className="logo">
          <Link to={"/"} className="temp-logo">
            <img src={MainLogo} className="logo-img" alt="navbar" />
          </Link>
        </div>
        <nav
          className={`mobile-nav-menu ${open ? "open" : ""}`}
          onClick={handleOpen}
        >
          <div className="logo mt-3">
            <Link to={"/"} className="temp-logo">
              <img src={MainLogo} className="img-fluid" alt="navbar" />
            </Link>
          </div>

          <FontAwesomeIcon
            icon="times"
            className="close-icon"
            onClick={handleOpen}
          />

          <div className="br"></div>

          <ul>
            {" "}
            {Navigation().map((nav, index) => {
              return (
                <li key={index}>
                  <Link
                    className={
                      activeRoute(`/${nav.route}/`)
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to={`/${nav.route}/`}
                  >
                    {nav.name.charAt(0).toUpperCase() + nav.name.slice(1)}{" "}
                  </Link>
                </li>
              );
            })}{" "}
          </ul>

          <div className="br"></div>

          <div className="col-sm-12 mobile-contacts">
            <ul>
              <li>
                <a href={`tel:${config.contacts.facebook}`}>
                  <FontAwesomeIcon icon="phone-alt" /> {config.contacts.tel[0]}{" "}
                </a>
              </li>
              <li>
                <a href={`tel:+37410511191`}>
                  <FontAwesomeIcon icon="phone-alt" /> {+37410511191}{" "}
                </a>
              </li>
              <li>
                <FontAwesomeIcon icon="calendar" />{" "}
                {`${config.lang.topbar.workingDays[lang]}`}{" "}
              </li>
              <li>
                <FontAwesomeIcon icon="clock" />{" "}
                {`${config.lang.topbar.workingHours}`}{" "}
              </li>
            </ul>

            <ul className="dropdown-list" aria-labelledby="">
              <li onClick={dropDowntoggle}>
                <a
                  className={` dropdown-list-item ${
                    lang === "en" ? " active" : " disabled"
                  }`}
                  href={() => false}
                  onClick={changeLanguage}
                  lang="en"
                >
                  Eng
                </a>
              </li>
              <li onClick={dropDowntoggle}>
                <a
                  className={` dropdown-list-item ${
                    lang === "ru" ? " active" : " disabled"
                  }`}
                  href={() => false}
                  onClick={changeLanguage}
                  lang="ru"
                >
                  Рус
                </a>
              </li>
              <li onClick={dropDowntoggle}>
                <a
                  className={` dropdown-list-item ${
                    lang === "hy" ? " active" : " disabled"
                  }`}
                  href={() => false}
                  onClick={changeLanguage}
                  lang="hy"
                >
                  Հայ
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div
          className={`mobile-nav-overlay ${open ? "open" : ""}`}
          onClick={handleOpen}
        ></div>
      </div>
    </div>
  );
}
