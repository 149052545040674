import React from 'react';
import config from '../../../config'
import {useLang} from '../../../helpers/language';

import facebookIcon from './icons/facebook.png';
import instagramIcon from './icons/instagram.png';

function SocialNetworks() {
    let {lang} = useLang();

    return (
        <div className="aboutus">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 content-left">
                        <h3>{
                            config.lang.homePage.socialNetwork.main[lang]
                        }</h3>
                    </div>
                    <div className="col-xl-6 col-lg-6 d-flex justify-content-around">
                        <a href="https://www.facebook.com/VOLIOSCOMPANY/" target="_blank" rel="noopener noreferrer">
                            <img src={facebookIcon}
                                alt=""/>
                        </a>
                        <a href="https://www.instagram.com/volios_cjsc/?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noopener noreferrer">
                            <img src={instagramIcon}
                                alt=""/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default SocialNetworks
