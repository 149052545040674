import config from '../../config';
// language stuff
import { useLang } from '../../helpers/language';

export function Navigation() {
	let { lang } = useLang();

	const navigationPrimary = [
		{
			name: config.lang.menu.home[lang],
			route: 'home',
			icon: 'fas fa-address-card',
		},
		{
			name: config.lang.menu.aboutUs[lang],
			route: 'about-us',
			icon: 'fas fa-address-card',
		},
		{
			name: config.lang.menu.projects[lang],
			route: 'projects',
			icon: 'fas fa-briefcase',
		},
		{
			name: config.lang.menu.services[lang],
			route: 'services',
			icon: 'fas fa-concierge-bell',
		},
		{
			name: config.lang.menu.news[lang],
			route: 'news',
			icon: 'fas fa-newspaper',
		},
		{
			name: config.lang.menu.contacts[lang],
			route: 'contacts',
			icon: 'fas fa-user-tag',
		},
		{
			name: config.lang.menu.joinUs[lang],
			route: 'join-us',
			icon: 'fas fa-users',
		},
	];

	return navigationPrimary;
}
