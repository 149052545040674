import React, { useState, useEffect } from "react";
import ProjectPaginate from "../modules/projects/projects";
import config from "../../config";
import { useLang } from "../../helpers/language";
import { Oval } from "react-loader-spinner";

export default function Projects() {
  let { lang } = useLang();
  const [isBusy, setBusy] = useState(true);
  useEffect(() => {
    (async () => {
      setBusy(false);
    })();
  }, [isBusy]);

  if (isBusy) return <Oval color="#04b4e0" className="loader" />;
  else
    return (
      <>
        <div className="projects">
          <div className="section full-width section-small">
            <div className={"page-top-img"}>
              <img
                src={`${config.api.MAIN_APP_URL}/default-banner.jpg`}
                alt="top"
              />
            </div>
            <div className="col-12 page-title">
              <h1>{config.lang.menu.projects[lang]}</h1>
            </div>
          </div>
          <div className="section section-small margin-0">
            <ProjectPaginate noTitle={true} />
          </div>
        </div>
      </>
    );
}
