import React, { useState, useEffect } from "react";
import { BigSection, SmallSection } from "../section/section";
import History from "../modules/history/history";
import Mission from "../modules/mission/mission";
import License from "../modules/license/license";
import Team from "../modules/team/team";
import Partners from "../modules/partners/partners";
import config from "../../config";
import { useLang } from "../../helpers/language";
import { Oval } from "react-loader-spinner";

export default function AboutUs() {
  const [isBusy, setBusy] = useState(true);
  const { lang } = useLang();

  useEffect(() => {
    (async () => {
      setBusy(false);
    })();
  }, [isBusy]);

  if (isBusy) return <Oval color="#04b4e0" className="loader" />;
  else
    return (
      <>
        <div className="about-us">
          <div className="section full-width section-small">
            <div className={"page-top-img"}>
              <img
                src={`${config.api.MAIN_APP_URL}/default-banner.jpg`}
                alt="top"
              />
            </div>
            <div className="col-12 page-title">
              <h1>{config.lang.menu.aboutUs[lang]}</h1>
            </div>
          </div>
          <SmallSection component={History} />
          <SmallSection component={Mission} />
          <SmallSection component={License} />
          <SmallSection component={Team} />
          <BigSection
            component={Partners}
            className="full-width"
          />
        </div>
      </>
    );
}
