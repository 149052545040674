import React, { useState, useEffect } from "react";
import { Oval } from "react-loader-spinner";

// components
import Video from "../modules/video/video";
import AnimatedNumbers from "../modules/animated-numbers/animatedNumbers";
import { SmallSection, BigSection } from "../section/section";
import Services from "../modules/services/services";
// eslint-disable-next-line
import AboutUs from "../modules/aboutus/aboutus";
import CTA from "../modules/cta/cta";
import SocialNetworks from "../modules/social-networks/socialNetworks";
// eslint-disable-next-line
import WhyChooseUs from "../modules/wchus/wchus";
import Projects from "../modules/projects/projects";
import Clients from "../modules/clients/clients";
import News from "../modules/news/news";
import BigText from "../modules/big-text/bigText";
import OurOpinion from "../modules/our-opinion/ourOpinion.jsx";
import config from "../../config";
import { useLang } from "../../helpers/language";

import OpinionbgImagae from "./download.jpg";

export default function Home() {
  const { lang } = useLang();

  return (
    <>
      <div className="video-box">
        <Video />
        <div className="video-overlay"></div>
        <h4 className="video-sub-slogan">
          {config.lang.homePage.subSlogan[lang]}{" "}
        </h4>
      </div>
      <SmallSection
        component={() => (
          <AnimatedNumbers counter1={7914} counter2={922} counter3={103} />
        )}
      />
      <BigSection component={Services} />
      <SmallSection component={CTA} className="full-width ruller" />
      <BigSection component={Projects} />
      <SmallSection component={SocialNetworks} className="full-width dots" />
      <SmallSection component={BigText} className="full-width bg-white" />
      <SmallSection
        component={OurOpinion}
        className="full-width"
        bgImage={OpinionbgImagae}
      />
      <BigSection component={Clients} className="full-width" />
      {/* <BigSection component={WhyChooseUs}/>
                <SmallSection component={AboutUs}
                    className="full-width dots"/> */}
      <BigSection component={News} />
    </>
  );
}
