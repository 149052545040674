import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import config from "../../../config";
import { useLang } from "../../../helpers/language";
import * as API from "../../../../src/helpers/api";
import { Oval } from "react-loader-spinner";

// Import Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper/core";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

function News() {
  const [isBusy, setBusy] = useState(true);
  let { lang } = useLang();
  let [news, setnews] = useState();
  let [screenWidth] = useState(window.innerWidth.toString());
  let slidesPerView = 1;
  // eslint-disable-next-line
  let slidesPerViewLogos = 1;
  if (screenWidth >= 500) {
    slidesPerView = 2;
    slidesPerViewLogos = 2;
  }

  if (screenWidth >= 900) {
    slidesPerView = 3;
    slidesPerViewLogos = 4;
  }

  if (screenWidth >= 1300) {
    slidesPerView = 4;
    slidesPerViewLogos = 6;
  }
  SwiperCore.use([Navigation]);
  SwiperCore.use([Pagination]);
  SwiperCore.use([Autoplay]);

  useEffect(() => {
    (async () => {
      let rowData = await API.get(config.api.news, {}, {
        "date": -1
      }, true);
      setnews(rowData);
      setBusy(false);
    })();
  }, [isBusy]);


  if (isBusy)
    return <Oval color="#04b4e0" className="loader" />;
  else
    return (
      <div className="services">
        <h2 className="section-title">
          {
            config.lang.homePage.news.title[lang]
          } </h2>
        {/* <p className="section-subtitle">
          {
            config.lang.homePage.news.subtitle[lang]
          } </p> */}
        <div className="section-content position-relative">
          <div className="swiper-button-next news-arrow-next"></div>
          <Swiper className="position-static"
            spaceBetween={0}
            slidesPerView={slidesPerView}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            props={
              {
                observer: true,
                observeParents: true,
                updateOnWindowResize: true
              }
            }
            observer={true}
            observeParents={true}
            updateOnWindowResize={
              () => {
                console.log(444);
              }
            }
            pagination={false}
          // pagination={{ clickable: true }}
          // scrollbar={{ draggable: true }}
          >
            {
              news.map((item, index) => (
                <SwiperSlide className="position-relative">
                  <div className="item">
                    <Link to={
                      `/news/${item._id
                      }`
                    }>
                      <div className="item-img">
                        <img src={
                          item?.uploads ? `${config.api.API_URL
                            }/${item?.uploads && item?.uploads[0]?.path
                            }` : null
                        }
                          alt="" />
                      </div>
                      <div className="item-title">
                        <h5> {
                          lang === "en" ? item.title?.en ? item.title?.en.length > 50 ? item.title?.en.slice(0, 50) + "..." : item.title?.en : "" : lang === "ru" ? item.title?.ru ? item.title?.ru.length > 50 ? item.title?.ru.slice(0, 50) + "..." : item.title?.ru : "" : lang === "hy" ? item.title?.hy ? item.title?.hy.length > 50 ? item.title?.hy.slice(0, 50) + "..." : item.title?.hy : "" : null
                        } </h5>
                      </div>
                    </Link>
                    <span className="item-date">
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                        <span className="date-text">
                          {` ${
                            config.lang.months[lang][
                              new Date(`${item.date}`).getMonth()
                            ]
                          } ${new Date(`${item.date}`).getDate()}`}
                        </span>
                      </span>
                  </div>
                </SwiperSlide>
              ))
            } </Swiper>
          <div className="swiper-button-prev news-arrow-prev"></div>
        </div>
      </div>
    );

}

export default News;
