import React, { useEffect, useState } from "react";
import config from "../../config.js";
import * as API from "../../helpers/api";
import { Oval } from "react-loader-spinner";

// language stuff
import { useLang } from "../../helpers/language";

export default function Topbar() {
  // language stuff
  //eslint-disable-next-line
  let { lang, setlang } = useLang();
  //eslint-disable-next-line
  const [contactPart, setcontactPart] = useState([]);
  const [isBusy, setBusy] = useState(true);
  //eslint-disable-next-line
  const changeLanguage = (e) => {
    if (e.target.lang === "ru") {
      setlang("ru");
    } else if (e.target.lang === "hy") {
      setlang("hy");
    } else if (e.target.lang === "en") {
      setlang("en");
    }
  };

  useEffect(() => {
    (async () => {
      let rawContactPart = API.get(
        config.api.contactPart,
        {},
        {},
        { destiny: "icon" }
      );
      let rawContactType = API.get(config.api.contactType);

      Promise.all([rawContactPart, rawContactType]).then((values) => {
        let initialArr = [];
        for (let i = 0; i < values[0].length; i++) {
          const item = values[0][i];
          values[1].forEach((el) => {
            if (item.contact_type_id === el._id) {
              item.href = el.href + item.value;
            }
          });
          initialArr.push(item);
        }
        setcontactPart(initialArr);
        setBusy(false);
      });
      setBusy(false);
    })();
  }, [isBusy]);

  if (isBusy) return <Oval color="#04b4e0" className="loader" />;
  else
    return (
      // <div className="header-top-bar">
      //   <div className="row">
      //     <div className="col-sm-12 col-lg-8 header-contact justify-content-center">
      //       <ul className="contacts-list">
      //           {contactPart?.map((item) => (
      //             <li className="contacts-list-item">
      //               <a href={item.href}>
      //                 <img className="contacts-list-item__img" src={item?.uploads ? `${config.api.API_URL}/${
      //                           item?.uploads && item?.uploads[0]?.path
      //                         }` : null}  alt='icon'/>
      //                 <p className="contacts-list-item__name" >{item.name[lang]}</p>
      //               </a>
      //             </li>
      //           ))}
      //         </ul>
      //     </div>
      //     <div className="col-sm-12 col-lg-4 header-social justify-content-center">
      //       <ul>
      //         <li>
      //           <span
      //             className={lang === "en" ? "selected lang-icon" : "lang-icon"}
      //             onClick={changeLanguage}
      //             lang="en"
      //           >
      //             Eng
      //           </span>
      //         </li>
      //         <li>
      //           <span
      //             className={lang === "ru" ? "selected lang-icon" : "lang-icon"}
      //             onClick={changeLanguage}
      //             lang="ru"
      //           >
      //             Рус
      //           </span>
      //         </li>
      //         <li>
      //           <span
      //             className={lang === "hy" ? "selected lang-icon" : "lang-icon"}
      //             onClick={changeLanguage}
      //             lang="hy"
      //           >
      //             Հայ
      //           </span>
      //         </li>
      //       </ul>
      //       <span className="divider"></span>
      //       {/* <ul>
      //         <li>
      //           <a href={`${contacts.facebook}`}>
      //             <FontAwesomeIcon icon={["fab", "facebook-f"]} />
      //           </a>
      //         </li>
      //         <li>
      //           <a href={`${contacts.instagram}`}>
      //             <FontAwesomeIcon icon={["fab", "instagram"]} />
      //           </a>
      //         </li>
      //       </ul> */}
      //     </div>
      //   </div>
      // </div>
      ''
    );
}
