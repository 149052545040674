import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';
import { useLang } from '../../helpers/language';
import { useLocation } from 'react-router-dom';
import * as API from '../../helpers/api';
import parse from 'html-react-parser';
import { Oval } from 'react-loader-spinner';
// Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

export default function News() {
  const [isBusy, setBusy] = useState(true);
  const id =
    useLocation().pathname.split('/')[
      useLocation().pathname.split('/').length - 1
    ];
  const { lang } = useLang();
  const [news, setNews] = useState({});
  const [slider, setSlider] = useState({});
  const [images, setImages] = useState([]);
  let [screenWidth] = useState(window.innerWidth.toString());
  let slidesPerView = 1;
  // eslint-disable-next-line
  let slidesPerViewLogos = 1;
  if (screenWidth >= 500) {
    slidesPerView = 2;
    slidesPerViewLogos = 2;
  }

  if (screenWidth >= 900) {
    slidesPerView = 3;
    slidesPerViewLogos = 4;
  }

  if (screenWidth >= 1300) {
    slidesPerView = 4;
    slidesPerViewLogos = 6;
  }
  SwiperCore.use([Navigation]);
  SwiperCore.use([Pagination]);
  SwiperCore.use([Autoplay]);

  useEffect(() => {
    (async () => {
      let getNews = await API.get(config.api.news, { _id: id }, {}, true);
      setNews(getNews[0]);
      if (getNews[0]?.slider_id) {
        let getSlider = await API.get(config.api.slider, {
          _id: getNews[0]?.slider_id,
        });
        setSlider(getSlider[0]);
        let images;
        if (getSlider[0]) {
          images = await API.get(config.api.upload, {
            obj_id: getSlider[0]._id,
          });
          setImages(images);
        }
      }
      setBusy(false);
    })();
  }, [isBusy, id]);

  if (isBusy) return <Oval color='#04b4e0' className='loader' />;
  else
    return (
      <div className='news news-single'>
        <div className='section full-width section-small'>
          <div className={'page-top-img'}>
            <img
              src={`${
                news
                  ? news.uploads
                    ? news.uploads[0]
                      ? config.api.API_URL + '/' + news.uploads[0].path
                      : ''
                    : ''
                  : `${config.api.MAIN_APP_URL}/default-banner.jpg`
              }
							`}
              alt='top'
            />
          </div>
          <div className='container'>
            <div className='row'>
              <div className='col-12 page-title'>
                <h1>
                  <Link to='/news'>{config.lang.menu.news[lang]}</Link>
                  {` / ${
                    lang === 'en'
                      ? news.title.en
                      : lang === 'ru'
                      ? news.title.ru
                      : lang === 'hy'
                      ? news.title.hy
                      : ''
                  }`}
                </h1>
                <span className='item-date'>
                  <i className='fa fa-calendar' aria-hidden='true'></i>
                  <span className='date-text'>
                    {` ${
                      config.lang.months[lang][
                        new Date(`${news.date}`).getMonth()
                      ]
                    } ${new Date(`${news.date}`).getDate()}`}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='section section-big'>
          <div className='single-page-container'>
            {lang === 'en'
              ? parse(news.desc?.en ? news.desc?.en : '')
              : lang === 'ru'
              ? parse(news.desc?.ru ? news.desc?.ru : '')
              : lang === 'hy'
              ? parse(news.desc?.hy ? news.desc?.hy : '')
              : ''}
          </div>
          {images.length > 0 && (
            <div className='single-page-container'>
              <Swiper
                className='position-static'
                spaceBetween={10}
                slidesPerView={slidesPerView}
                navigation
                observer={true}
                observeParents={true}
                pagination={{ clickable: true }}
              >
                {images.map((item, index) => (
                  <SwiperSlide className='position-relative'>
                    <div className='news-slider'>
                      <div className='news-slider-img'>
                        <img
                          src={`${config.api.API_URL}/${item?.path}`}
                          alt=''
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>
      </div>
    );
}
