import React from 'react';

export function SmallSection ({ component: Component,bgImage: OpinionbgImagae, props, ...additionalClasses}) {
    if (OpinionbgImagae) {
        return (
            <div className={`section section-small bgImage ${{...additionalClasses}.className}`} style={{backgroundImage: `url(${OpinionbgImagae})`}}>
                <Component props={props}/>
            </div>
        )
    }
    return (
        <div className={`section section-small ${{...additionalClasses}.className}`}>
            <Component props={props}/>
        </div>
    )
}

export function BigSection ({ component: Component,bgImage: OpinionbgImagae }) {
    return (
        <div className="section section-big" >
            <Component />
        </div>
    )
}