import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../../config";
import { useLang } from "../../helpers/language";
import * as API from "../../helpers/api";
import { Oval } from "react-loader-spinner";

import footerlogo from "./footer-logo.svg";
function Footer() {
  let { lang } = useLang();
  const [isBusy, setBusy] = useState(true);
  // const [aboutus, setaboutus] = useState({
  //   desc: {
  //     en: "",
  //     ru: "",
  //     hy: "",
  //   },
  // });
  const [services, setservices] = useState([]);
  const [contactParts, setcontactParts] = useState([]);

  useEffect(() => {
    (async () => {
      // let rawAboutUs = await API.get(config.api.aboutUs.history);
      let rawServices = API.get(config.api.services, {}, { index: 1 });
      let rawContactParts = API.get(
        config.api.contactPart,
        {},
        {},
        { destiny: "icon" }
      );
      let rawContactTypes = API.get(config.api.contactType);
      Promise.all([rawServices, rawContactParts, rawContactTypes]).then(
        (values) => {
          setservices(values[0]);
          let initContactParts = [];
          for (let i = 0; i < values[1].length; i++) {
            const item = values[1][i];
            values[2].forEach((el) => {
              if (item.contact_type_id === el._id) {
                item.href = el.href + item.value;
              }
            });
            initContactParts.push(item);
          }
          setcontactParts(initContactParts);
          setBusy(false);
        }
      );
    })();
  }, []);

  if (isBusy) return <Oval color="#04b4e0" className="loader" />;
  else
    return (
      <>
        <div className="footer-wrap">
          <div className="footer">
            <div className="container">
              <div className="row">
                {/* <div className="col-xs-12 col-md-8 main-footer-box">
                  <h3 className="title title-bar-lg1">
                    {config.lang.menu.aboutUs[lang]}
                  </h3>
                  <div className="about-company">
                    <span>
                      {lang === "en"
                        ? parse(
                            aboutus.desc?.en
                              ? aboutus.desc?.en.slice(0, 400) + "..."
                              : ""
                          )
                        : lang === "ru"
                        ? parse(
                            aboutus.desc?.ru
                              ? aboutus.desc?.ru.slice(0, 400) + "..."
                              : ""
                          )
                        : lang === "hy"
                        ? parse(
                            aboutus.desc?.hy
                              ? aboutus.desc?.hy.slice(0, 400) + "..."
                              : ""
                          )
                        : ""}
                    </span>
                  </div>
                </div> */}
                {/* <div className="col-xs-12 col-md-4 main-footer-box">
                  <h3 className="title title-bar-lg1">
                    {config.lang.footer.social[lang]}
                  </h3>
                  <ul className="footer-social">
                    <li>
                      <a href={`${contacts.facebook}`}>
                        <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                      </a>
                    </li>
                    <li>
                      <a href={`${contacts.instagram}`}>
                        <FontAwesomeIcon icon={["fab", "instagram"]} />
                      </a>
                    </li>
                  </ul>
                </div> */}
                <div className="col-xs-12 col-md-4 main-footer-box">
                  <h3 className="title title-bar-lg1">
                    {config.lang.menu.services[lang]}
                  </h3>
                  <div className="menu-service-list-container">
                    <ul>
                      {Array.isArray(services) &&
                        services.map((item, key) => (
                          <li key={key}>
                            <a href={`/services/${item._id}`}>
                              <FontAwesomeIcon
                                icon="chevron-right"
                                className="mr-2"
                                style={{
                                  marginRight: 10,
                                }}
                              />
                              {lang === "en"
                                ? item.name?.en
                                : lang === "ru"
                                ? item.name?.ru
                                : lang === "hy"
                                ? item.name?.hy
                                : null}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <img src={footerlogo} alt="" width="75%" />
                </div>
                <div className="col-xs-12 col-md-4 main-footer-box">
                  <h3 className="title title-bar-lg1">
                    {config.lang.menu.contacts[lang]}
                  </h3>
                  <ul className="footer-contacts">
                    {contactParts?.map((item, key) => (
                      <li key={key} className="footer-contacts-list-item">
                        <a href={`${item.href}`}>
                          <img
                            className="contacts-list-item__img"
                            src={
                              item?.uploads
                                ? `${config.api.API_URL}/${
                                    item?.uploads && item?.uploads[0]?.path
                                  }`
                                : null
                            }
                            alt="icon"
                          />
                          <p>{item.name[lang]}</p>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom-wrap">
            <div className="copyright">
              © Copyright Volios 2020. Designed and Developed by{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "white" }}
                href="https://deepmindsystems.com"
              >
                Deepmindsystems.com
              </a>
            </div>
          </div>
        </div>
      </>
    );
}

export default Footer;
