import React, { useState, useEffect } from "react";
import config from "../../../config";
import * as API from "../../../../src/helpers/api";
import { Oval } from 'react-loader-spinner';
import { useLang } from "../../../helpers/language";
// Import Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper/core";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

function Clients() {
  const [isBusy, setBusy] = useState(true);
  let { lang } = useLang();
  let [clients, setclients] = useState();
  let [screenWidth] = useState(window.innerWidth.toString());
  let slidesPerView = 1;
  //eslint-disable-next-line
  let slidesPerViewLogos = 1;
  if (screenWidth >= 500) {
    slidesPerView = 2;
    slidesPerViewLogos = 2;
  }

  if (screenWidth >= 900) {
    slidesPerView = 3;
    slidesPerViewLogos = 4;
  }

  if (screenWidth >= 1300) {
    slidesPerView = 4;
    slidesPerViewLogos = 6;
  }
  SwiperCore.use([Navigation]);
  SwiperCore.use([Pagination]);
  SwiperCore.use([Autoplay]);


  useEffect(() => {
    (async () => {
      let rowData = await API.get(config.api.clients,{},{},true);
      // for (let i = 0; i < rowData.length; i++) {
      //   let data = rowData;
      //   let image = await API.getImagesByObjName(data);
      //   data.imageUrl = `${config.api.API_URL}/${image[0] && image[0].path}`;
      //   initialArray.push(data);
      // }

      setclients(rowData);

      setBusy(false);
    })();
  }, [isBusy]);
  if (isBusy) return <Oval color="#04b4e0" className="loader" />;
  else
    return (
      
      <div className="clients">
        <h2 className="section-title" style={{marginBottom: 50,color: '#000'}}>
          {config.lang.homePage.clients.title[lang]}
        </h2>
        <Swiper
            spaceBetween={0}
            slidesPerView={slidesPerView}
            navigation
            props={{
              observer: true,
              observeParents: true,
              updateOnWindowResize: true,
            }}
            observer={true}
            observeParents={true}
            updateOnWindowResize={() => {
              console.log(444);
            }}
            pagination={false}
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
          >
          {clients.map((item, index) => (
            <SwiperSlide className="d-flex justify-content-center">
            <div key={index} style={{ outline: "none" }}>
              <img src={item?.uploads ? `${config.api.API_URL}/${
                                item?.uploads && item?.uploads[0]?.path
                              }` : null} alt="clients" />
            </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
}

export default Clients;
