import React from 'react';
import bgImagae from './download.png';
import './bigText.scss';
function BigText() {
    return (
        <div className="bigTextWrapper">
            <h1 style={{backgroundImage: `url(${bgImagae})`}} className="bigText">Volios</h1>
        </div>
    )
}
 

export default BigText