import React, { useState, useEffect } from "react";
import { useLang } from "../../../helpers/language";
import parse from "html-react-parser";
import config from "../../../config";
import * as API from "../../../helpers/api";

function History() {
  const [isBusy, setBusy] = useState(true);
  const { lang } = useLang();
  const [history, sethistory] = useState("");

  useEffect(() => {
    (async () => {
      let getRawData = await API.get(config.api.aboutUs.history);
      if (!!getRawData) sethistory(getRawData[0]);
      setBusy(false);
    })();
  }, [isBusy]);

  return (
    <div className="history">
      <h2 className="section-title underline">
        {config.lang.aboutUs.history[lang]}
      </h2>
      <div className="section-content min-margin">
        {lang === "en"
          ? parse(history?.desc?.en ? history?.desc?.en : "")
          : lang === "ru"
          ? parse(history?.desc?.ru ? history?.desc?.ru : "")
          : lang === "hy"
          ? parse(history?.desc?.hy ? history?.desc?.hy : "")
          : ""}
      </div>
    </div>
  );
}

export default History;
