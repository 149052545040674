import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Pagination from "../pagination";
import config from "../../../config";
import * as API from "../../../../src/helpers/api";
import { useLang } from "../../../helpers/language";
import parse from "html-react-parser";

const Items = ({ items, loading }) => {
  let { lang } = useLang();

  if (loading) {
    return <h2>Loading...</h2>;
  }

  return items.map((item, key) => (
    <div className="news-card row" key={key}>
      <Link to={`/news/${item._id}`} className="row">
        <div className="news-card-img col-md-12 col-lg-4">
          <img
            src={`${config.api.API_URL}/${
              item
                ? item.uploads
                  ? item.uploads[0]
                    ? item.uploads[0].path
                    : ""
                  : ""
                : ""
            }`}
            alt=""
          />
        </div>
        <div className="news-card-content col-md-12 col-lg-8">
          <span className="item-date">
            <i className="fa fa-calendar" aria-hidden="true"></i>
            <span className="date-text">
              {` ${
                config.lang.months[lang][new Date(`${item.date}`).getMonth()]
              } ${new Date(`${item.date}`).getDate()}`}
            </span>
          </span>
          <h3>
            {lang === "en"
              ? item.title.en
              : lang === "ru"
              ? item.title.ru
              : lang === "hy"
              ? item.title.hy
              : null}
          </h3>
          <span>
            {lang === "en"
              ? parse(item.desc?.en ? item.desc?.en.slice(0, 200) + "..." : "")
              : lang === "ru"
              ? parse(item.desc?.ru ? item.desc?.ru.slice(0, 200) + "..." : "")
              : lang === "hy"
              ? parse(item.desc?.hy ? item.desc?.hy.slice(0, 200) + "..." : "")
              : ""}
          </span>
        </div>
      </Link>
    </div>
  ));
};

function News() {
  const [news, setnews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(5);
  // Get current items
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentOnes = news.slice(indexOfFirst, indexOfLast);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    (async () => {
      let rowData = await API.get(config.api.news, {}, { date: -1 }, true);
      setnews(rowData);
      setLoading(false);
    })();
  }, []);

  return (
    <div className="news-vertical">
      <div className="section-content">
        <Items items={currentOnes} loading={loading} />
        <Pagination
          dataPerPage={perPage}
          totalCount={news.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
}

export default News;
