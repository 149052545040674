import React, { useState, useEffect } from "react";
import config from "../../../config";
import { useLang } from "../../../helpers/language";
import * as API from "../../../helpers/api";
import { Oval } from "react-loader-spinner";

// Import Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper/core";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

import LightGallery from "lightgallery/react";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

function License() {
  let { lang } = useLang();
  const [isBusy, setBusy] = useState(true);
  const [license, setLicense] = useState(true);
  let [screenWidth] = useState(window.innerWidth.toString());
  let slidesPerView = 1;
  //eslint-disable-next-line
  let slidesPerViewLogos = 1;
  if (screenWidth >= 500) {
    slidesPerView = 2;
    slidesPerViewLogos = 2;
  }

  if (screenWidth >= 900) {
    slidesPerView = 3;
    slidesPerViewLogos = 4;
  }

  if (screenWidth >= 1300) {
    slidesPerView = 4;
    slidesPerViewLogos = 6;
  }
  SwiperCore.use([Navigation]);
  SwiperCore.use([Pagination]);
  SwiperCore.use([Autoplay]);

  useEffect(() => {
    (async () => {
      let rowData = await API.get(
        config.api.aboutUs.license,
        {},
        {
          index: 1,
        },
        true
      );

      for (let i = 0; i < rowData.length; i++) {
        const data = rowData[i];
        data.imageUrl = `${config.api.API_URL}/${
          data.uploads
            ? data.uploads
              ? data.uploads[0]
                ? data.uploads[0].path
                : ""
              : ""
            : ""
        }`;
      }

      setLicense(rowData);
      setBusy(false);
    })();
  }, [isBusy]);

  if (isBusy) return <Oval color="#04b4e0" className="loader" />;
  else
    return (
      <div className="team">
        <h2 className="section-title">{config.lang.aboutUs.license[lang]}</h2>
        <div className="section-content min-margin">
          <Swiper
            spaceBetween={0}
            slidesPerView={slidesPerView}
            navigation
            props={{
              observer: true,
              observeParents: true,
              updateOnWindowResize: true,
            }}
            observer={true}
            observeParents={true}
            updateOnWindowResize={() => {
              console.log(444);
            }}
            // pagination={true}
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
          >
            <LightGallery elementClassNames="swiper-wrapper">
              {license.map((item, index) => (
                <div
                  className="swiper-slide license-slide"
                  data-src={item.imageUrl}
                  key={index}
                >
                  <a data-src={item.imageUrl}>
                    <div className="item" key={index}>
                      <div className="item-img-wrapper p-4">
                        <img
                          src={item.imageUrl}
                          alt={
                            lang === "en"
                              ? item?.name?.en
                              : lang === "ru"
                              ? item?.name?.ru
                              : lang === "hy"
                              ? item?.name?.hy
                              : ""
                          }
                        />
                      </div>
                      <div className="item-content">
                        <p>
                          {lang === "en"
                            ? item?.name?.en
                            : lang === "ru"
                            ? item?.name?.ru
                            : lang === "hy"
                            ? item?.name?.hy
                            : ""}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </LightGallery>
          </Swiper>
        </div>
      </div>
    );
}

export default License;
