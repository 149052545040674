import React, { useEffect, useRef } from 'react';
import Footer from '../footer/footer';
import Navbar from "../header/navbar";
import Topbar from "../header/topbar";

export default function Main({ component: Component, ...rest }) {
	const header = useRef(null)
	const content = useRef(null)
	const handleScroll = () => {
		if (window.scrollY < 1) {
			// setactiveClass('');
			header.current.classList.remove('fixed')
			content.current.classList.remove('fixed')
		} else if (window.scrollY > 1) {
			// setactiveClass('fixed');
			header.current.classList.add('fixed')
			content.current.classList.add('fixed')
		}
	}
	useEffect(() => {
		(async () => {
			window.addEventListener('scroll', handleScroll);
		})();
		return  () => {
			window.removeEventListener('scroll', handleScroll);
		}
	}, []);

	return (
		<>
			<header className={`main-header`} ref={header}>
				<div className="main-header-block">
					<Topbar />
					<Navbar />
				</div>
			</header>
			<div className={`content-wrapper`} ref={content}>
				<Component />
			</div>
			<Footer />
		</>
	);
	// }
}
