import React, { useState, useEffect } from "react";
import { SmallSection } from "../section/section";
import { Oval } from "react-loader-spinner";

import { Link } from "react-router-dom";
import config from "../../config";
import { useLang } from "../../helpers/language";
import * as API from "../../helpers/api";
import parse from "html-react-parser";

function ServiceModule() {
  let { lang } = useLang();
  const [isBusy, setBusy] = useState(true);
  const [services, setservices] = useState(true);

  useEffect(() => {
    (async () => {
      let rowData = await API.get(config.api.services, {}, {name: 1}, true);
      setservices(rowData);
      setBusy(false);
    })();
  }, [isBusy]);

  if (isBusy) return <Oval color="#04b4e0" className="loader" />;
  else
    return (
      <div className="section-content row">
        {services.map((item, key) => {
          return (
            <div
              className="col-lg-3 col-md-6 col-sm-6 col-12 item-wrapper"
              key={key}
            >
              <div className="item">
                <Link to={`/services/${item._id}`}>
                  <div className="item-img">
                    <img
                      src={`${config.api.API_URL}/${
                        item
                          ? item.uploads
                            ? item.uploads[0]
                              ? item.uploads[0].path
                              : ""
                            : ""
                          : ""
                      }`}
                      alt=""
                    />
                  </div>
                  <div className="item-title">
                    <h5>
                      {lang === "en"
                        ? parse(item.name.en ? item.name.en.slice(0, 20) : "")
                        : lang === "ru"
                        ? parse(item.name.ru ? item.name.ru.slice(0, 20) : "")
                        : lang === "hy"
                        ? parse(item.name.hy ? item.name.hy.slice(0, 20) : "")
                        : ""}
                      ...
                    </h5>
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    );
}

export default function Services() {
  let { lang } = useLang();
  const [isBusy, setBusy] = useState(true);
  useEffect(() => {
    (async () => {
      setBusy(false);
    })();
  }, [isBusy]);

  if (isBusy) return <Oval color="#04b4e0" className="loader" />;
  else
    return (
      <>
        <div className="services">
          <div className="section full-width section-small">
            <div className={"page-top-img"}>
              <img
                src={`${config.api.MAIN_APP_URL}/default-banner.jpg`}
                alt="top"
              />
            </div>
            <div className="col-12 page-title">
              <h1>{config.lang.menu.services[lang]}</h1>
            </div>
          </div>
          <SmallSection component={ServiceModule} className="margin-0" />
        </div>
      </>
    );
}
