import React from 'react';

function Video() {
	return (
		<video className='video' loop={true} autoPlay muted>
			<source src='/Volios-1.m4v' type='video/mp4' />
		</video>
	);
}

export default Video;
