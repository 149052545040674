import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useLang } from "../../helpers/language";
import config from "../../config";
import * as API from "../../helpers/api";
import parse from "html-react-parser";
import { Oval } from "react-loader-spinner";

export default function Service() {
  const [isBusy, setBusy] = useState(true);
  const id =
    useLocation().pathname.split("/")[
      useLocation().pathname.split("/").length - 1
    ];
  const { lang } = useLang();
  const [service, setservice] = useState({});

  useEffect(() => {
    (async () => {
      let getRawData = await API.get(
        config.api.services,
        { _id: id },
        {},
        true
      );
      setservice(getRawData[0]);
      setBusy(false);
    })();
  }, [isBusy, id]);

  if (isBusy) return <Oval color="#04b4e0" className="loader" />;
  else
    return (
      <div className="services">
        <div className="section full-width section-small">
          <div className={"page-top-img"}>
            <img
              alt="top"
              src={`${
                service
                  ? service.uploads
                    ? service.uploads[0]
                      ? config.api.API_URL + "/" + service.uploads[0].path
                      : ""
                    : ""
                  : `${config.api.MAIN_APP_URL}/default-banner.jpg`
              }
							`}
            />
          </div>
          <div className="col-12 page-title">
            <h1>
              <Link to="/services">{config.lang.menu.services[lang]}</Link>
              {` / ${
                lang === "en"
                  ? service.name.en
                  : lang === "ru"
                  ? service.name.ru
                  : lang === "hy"
                  ? service.name.hy
                  : ""
              }`}
            </h1>
          </div>
        </div>
        <div className="section section-big">
          <div className="single-page-container">
            {lang === "en"
              ? parse(service.desc.en ? service.desc.en : "")
              : lang === "ru"
              ? parse(service.desc.ru ? service.desc.ru : "")
              : lang === "hy"
              ? parse(service.desc.hy ? service.desc.hy : "")
              : ""}
          </div>
        </div>
      </div>
    );
}
