import React, { useState, useEffect } from "react";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import config from "../../../config";
import { useLang } from "../../../helpers/language";
import * as API from "../../../helpers/api";
import { Oval } from "react-loader-spinner";

function Contacts(props) {
  const { lang } = useLang();
  const [isBusy, setBusy] = useState(true);
  const [contactPart, setcontactPart] = useState([]);

  useEffect(() => {
    (async () => {
      let rawContactPart = API.get(
        config.api.contactPart,
        {},
        {},
        { destiny: "icon" }
      );
      let rawContactType = API.get(config.api.contactType);

      Promise.all([rawContactPart, rawContactType]).then((values) => {
        let initialArr = [];
        for (let i = 0; i < values[0].length; i++) {
          const item = values[0][i];
          values[1].forEach((el) => {
            if (item.contact_type_id === el._id) {
              item.href = el.href;
            }
          });
          initialArr.push(item);
        }
        setcontactPart(initialArr);
        setBusy(false);
      });
      setBusy(false);
    })();
  }, [isBusy]);

  if (isBusy) return <Oval color="#04b4e0" className="loader" />;
  else
    return (
      <div className="contacts">
        <div className="section-content min-margin">
          <div className="row">
            <div className="col-sm-3">
              <ul className="contacts-list">
                {contactPart?.map((item) => {
                  return (
                  <li className="contacts-list-item">
                    <a href={`${item.href}${item.value}`}>
                      <img className="contacts-list-item__img" src={item?.uploads ? `${config.api.API_URL}/${
                                item?.uploads && item?.uploads[0]?.path
                              }` : null}  alt='icon'/>
                      <p className="contacts-list-item__name" >{item.name[lang]}</p>
                    </a>
                  </li>
                )})}
              </ul>
            </div>
            <div className="col-sm-9" style={{ minHeight: 300 }}>
              <YMaps>
                <Map
                  style={{ width: "100%", height: "100%" }}
                  defaultState={{
                    center: [40.168765, 44.514602],
                    zoom: 16,
                  }}
                >
                  <Placemark geometry={[40.168765, 44.514602]} />
                </Map>
              </YMaps>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Contacts;

/*

				  if (item.type === 'facebook') { linktype = '' }
				  else if (item.type === 'phone') { linktype = 'tel:' }
				  else if (item.type === 'viber') { linktype = 'viber://contact?number=' }
				  else if (item.type === 'whatsapp') { linktype = 'https://api.whatsapp.com/send?phone=' }
				  else if (item.type === 'email') { linktype = 'mailto:' }

*/
