import React, { useEffect, useState } from "react";
import { Language } from "./helpers/language";
import { Oval } from "react-loader-spinner";

// fontwasome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  fab,
  faFacebookF,
  faInstagram,
  faWhatsapp,
  faViber,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare,
  faCoffee,
  faPhoneAlt,
  faClock,
  faChevronRight,
  faChevronLeft,
  faUserFriends,
  faChartArea,
  faProjectDiagram,
  faPhoneVolume,
  faCaretRight,
  faEnvelope,
  faTasks,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

// STYLES
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.scss";

// Helpers
import history from "./helpers/history";
import { useStateWithLocalStorage } from "./helpers/storage";

//Routing
import { Router, Route, Switch } from "react-router-dom";
import { cookies, setCookies } from "./helpers/cookies";
import * as API from "./helpers/api";
import config from "./config";
import axios from "axios";

// Components
import Portfolio from "./components/portfolio/portfolio";
import PortfolioList from "./components/portfolio/portfolio-list";
import Services from "./components/services/services";
import Service from "./components/services/service";
import Projects from "./components/projects/projects";
import Project from "./components/projects/project";
import Contacts from "./components/contacts/contacts";
import NewsList from "./components/news/news-list";
import News from "./components/news/news";
import JoinUs from "./components/join-us/joinUs";
import AboutUs from "./components/about-us/aboutUs";

// global components
import Home from "./components/home/home";
import Main from "./components/main/main";

library.add(
  fab,
  faCheckSquare,
  faCoffee,
  faClock,
  faPhoneAlt,
  faFacebookF,
  faInstagram,
  faChevronRight,
  faChevronLeft,
  faUserFriends,
  faChartArea,
  faProjectDiagram,
  faPhoneVolume,
  faCaretRight,
  faWhatsapp,
  faEnvelope,
  faViber,
  faTasks,
  faBars,
  faTimes
);

function ScrollToTop() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

function RouterComponent() {
  window.scrollTo(0, 0);
  return (
    <Route>
      <ScrollToTop />
      <Route exact path="/" component={Home} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/join-us" component={JoinUs} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/services/:id" component={Service} />
      <Route exact path="/projects" component={Projects} />
      <Route exact path="/projects/:id" component={Project} />
      <Route exact path="/news" component={NewsList} />
      <Route exact path="/news/:id" component={News} />
      <Route exact path="/portfolio" component={PortfolioList} />
      <Route exact path="/portfolio/:id" component={Portfolio} />
      <Route exact path="/contacts" component={Contacts} />
    </Route>
  );
}

function App() {
  let [lang, setlang] = useStateWithLocalStorage("language");
  let [loggedIn, setLoggedIn] = useState(false);
  let url = new URL(window.location.href);
  let restParams = {
    lang: url.searchParams.get("lang"),
    theme: url.searchParams.get("theme"),
  };

  async function login(keyfile) {
    if (keyfile) {
      API.post(config.authentification.login, { keyfile })
        .then(async ({ token }) => {
          API.settings.headers.keyfile = token;
          setCookies(token);
          setLoggedIn(true);
        })
        .catch((error) => {
          console.error(error);
          setLoggedIn(false);
        });
    } else {
      setLoggedIn(false);
      console.log(
        "%c There is no such a keyfile! ",
        "background: #222; color: #04b4e0"
      );
    }
  }

  async function checkToken() {
    let token = cookies.get(config.authentification.tokenAddress);
    if (token) {
      API.post(config.authentification.verify, {
        token,
      })
        .then(async (res) => {
          if (res.status === "success" || res.status === 200) {
            setCookies(token);
            setLoggedIn(true);
          }
        })
        .catch((res) => {
          setLoggedIn(false);
          console.error(res);
          console.error(`Please don't edit cookies manually`);
          cookies.remove("authorization");
        });
    } else {
      setLoggedIn(false);
      login(config.authentification.key);
    }
  }

  useEffect(() => {
    (async () => {
      await checkToken();
      if (
        restParams.lang &&
        (restParams.lang === "en" ||
          restParams.lang === "hy" ||
          restParams.lang === "ru")
      ) {
        setlang(restParams.lang);
      } else {
        setTimeout(() => {
          axios
            .get("https://ipapi.co/json/")
            .then(({ data }) => {
              document.getElementById("meta-locale").content = data.country;
              if (lang !== "en" && lang !== "ru" && lang !== "hy") {
                if (data.country === "AM") {
                  setlang("am");
                  localStorage.country = "AM";
                } else {
                  setlang("ru");
                  localStorage.country = "RU";
                }
              }
            })
            .catch((error) => {
              console.log(error);
              if (lang !== "en" && lang !== "ru" && lang !== "hy") {
                setlang("hy");
                localStorage.country = "AM";
              }
            });
        }, 0);
        if (lang !== "en" && lang !== "ru" && lang !== "hy") {
          setlang("hy");
        }
      }
    })();
    // eslint-disable-next-line
  }, [loggedIn]);

  if (loggedIn === null || loggedIn === false) {
    return <Oval color="#04b4e0" className="loader" />;
  } else if (loggedIn === true) {
    return (
      <Language.Provider value={{ lang, setlang }}>
        <Router history={history}>
          <Switch>
            <Main component={RouterComponent} />
          </Switch>
        </Router>
      </Language.Provider>
    );
  }
}

export default App;
