import React, { useState, useEffect } from "react";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import config from "../../config";
import * as API from "../../helpers/api";
import { Oval } from "react-loader-spinner";

export default function JoinUs() {
  const [isBusy, setBusy] = useState(true);
  const { lang } = useLang();
  const [joinUs, setjoinUs] = useState("");

  useEffect(() => {
    (async () => {
      let currentItem = await API.get(
        config.api.joinus,
        {},
        {},
        { destiny: "pdf" }
      );
      setjoinUs(currentItem[0]);
      setBusy(false);
    })();
  }, [isBusy]);

  if (isBusy) return <Oval color="#04b4e0" className="loader" />;
  else
    return (
      <>
        <div className="services">
          <div className="section full-width section-small">
            <div className={"page-top-img"}>
              <img
                src={`${config.api.MAIN_APP_URL}/default-banner.jpg`}
                alt="top"
                className={"page-top-img"}
              />
            </div>
            <div className="col-12 page-title">
              <h1>{config.lang.menu.joinUs[lang]}</h1>
            </div>
          </div>
          <div className={`section section-small`}>
            <div className="text">
              {lang === "en"
                ? parse(joinUs.desc?.en ? joinUs.desc?.en : "")
                : lang === "ru"
                ? parse(joinUs.desc?.ru ? joinUs.desc?.ru : "")
                : lang === "hy"
                ? parse(joinUs.desc?.hy ? joinUs.desc?.hy : "")
                : ""}
            </div>
            {(joinUs
              ? joinUs.uploads
                ? joinUs.uploads[0]
                  ? joinUs.uploads[0].path
                  : ""
                : ""
              : "") && (
              <a
                href={`${config.api.API_URL}/${
                  joinUs
                    ? joinUs.uploads
                      ? joinUs.uploads[0]
                        ? joinUs.uploads[0].path
                        : ""
                      : ""
                    : ""
                }`}
                className="item-btn download-file "
              >
                {config.lang.joinUs.download[lang]}
              </a>
            )}
          </div>
        </div>
      </>
    );
}
