const config = {
  api: {
    contacts: "contact-us",
    news: "news",
    joinus: "join-us",
    services: "services",
    animatedNumbers: "animated-numbers",
    portfolio: "portfolio",
    clients: "clients",
    projects: "projects",
    partners: "partners",
    wchus: "wchus",
    opinion: "opinion",
    aboutUs: {
      mission: "aboutus-mission",
      history: "aboutus-history",
      team: "aboutus-team",
      license: "aboutus-license",
    },
    contactPart: "contact-part",
    contactPage: "contact-page",
    contactType: "contact-type",
    positions: "position",
    slider: "slider",
    upload: "uploads",
    API_URL: process.env.REACT_APP_API_URL,
    MAIN_APP_URL: process.env.REACT_APP_MAIN_APP_URL,
    API_Version: "v2",
  },
  authentification: {
    login: "/login/keyfile",
    verify: "/verify",
    tokenAddress: "keyfile",
    key: process.env.REACT_APP_API_KEY,
  },
  contacts: {
    tel: ["+37410511188"],
    whatsapp: "+37410511188",
    viber: "+37410511188",
    facebook: "https://www.facebook.com/VOLIOSCompany/",
    instagram: "https://www.facebook.com/VOLIOSCompany/",
    email: "contact@volios.am",
  },
  lang: {
    topbar: {
      workingDays: {
        hy: "Երկուշաբթի - Ուրբաթ",
        ru: "Понедельник - Пятница",
        en: "Monday - Friday",
      },
      workingHours: "09:00 - 18:00",
    },
    menu: {
      home: {
        hy: "Հիմնական",
        ru: "Главная",
        en: "Home",
      },
      aboutUs: {
        hy: "Մեր մասին",
        ru: "О нас",
        en: "About us",
      },
      portfolio: {
        hy: "Նախագծեր",
        ru: "Проекты",
        en: "Projects",
      },
      services: {
        hy: "Ծառայություններ",
        ru: "Сервисы",
        en: "Services",
      },
      projects: {
        hy: "Նախագծեր",
        ru: "Проекты",
        en: "Projects",
      },
      contacts: {
        hy: "Կոնտակտներ",
        ru: "Контакты",
        en: "Contacts",
      },
      news: {
        hy: "Նորություններ",
        ru: "Новости",
        en: "News",
      },
      joinUs: {
        hy: "Միացեք",
        ru: "Присоединяетесь",
        en: "Join us",
      },
    },
    joinUs: {
      download: {
        en: "download CV",
        ru: "скачать CV",
        hy: "ներբեռնել CV-ն",
      },
    },
    homePage: {
      slogan: {
        hy: "«Վոլիոս նախագծային ինստիտուտ» ՓԲԸ",
        ru: "ЗАО «Проектный институт Волиос»",
        en: 'Volios  Institute of civil engineering" CJSC',
      },
      subSlogan: {
        hy: "Կարգապահություն, որակ, առաջընթաց",
        ru: "Дисциплина, качество, прогресс",
        en: "Discipline, quality, progress",
      },
      numbers: [
        {
          hy: "Ավարտված նախագծեր",
          ru: "Реализованные проекты",
          en: "Completed projects",
        },
        {
          hy: "Ընթացիկ նախագծեր",
          ru: "Текущие проекты",
          en: "Ongoing projects",
        },
        {
          hy: "Աշխատակիցներ",
          ru: "Сотрудники",
          en: "Our Staff",
        },
      ],
      services: {
        title: {
          hy: "Հիմնական Ծառայություններ",
          ru: "Основные Сервисы",
          en: "Main Services",
        },
        subtitle: {
          hy: "Հիմնական Ծառայություններ",
          ru: "Основные Сервисы",
          en: "Main Services",
        },
      },
      aboutUs: {
        main: {
          hy: "Իմացեք մեր մասին ավելին",
          ru: "Узнайте о нас больше",
          en: "Learn more about us",
        },
        button: {
          hy: "Մանրամասն",
          ru: "Подробно",
          en: "More details",
        },
      },
      socialNetwork: {
        main: {
          hy: "Գտեք մեզ սոցցանցերում",
          ru: "Найдите нас в социальных сетях",
          en: "Found us at social networks",
        },
      },
      wchus: {
        title: {
          hy: "Ինչու՞ ընտրել մեզ։",
          ru: "Почему мы?",
          en: "Why work with us?",
        },
        subtitle: {
          hy: "Մենք նախագծում ենք այն, ինչ Դուք մտածում եք",
          ru: "Мы проектируем то, что вы думаете",
          en: "We will prototype your vision",
        },
        content: [
          {
            hy: "Մեր հաջողության գաղտնիքը ոլորտի լավագույն մասնագետներից բաղկացած մեր թիմն է։",
            ru: "Наши высококвалифицированные сотрудники - залог нашего успеха.",
            en: "Our highly qualified employees are the key of our success.",
          },
          {
            hy: "Մենք անհատական մոտեցում և ճկուն լուծումներ ենք առաջարկում մեր գործընկերներին։",
            ru: "Мы предлагаем нашим партнерам индивидуальный подход и гибкие решения.",
            en: "We offer individual approach and flexible solutions to our partners.",
          },
          {
            hy: "Ընկերությունում կարևորվում է առողջ, ստեղծագործ աշխատանքային մթնոլորտ, որը խթանում է աշխատակիցների մասնագիտական և անձնային աճը։",
            ru: "Мы ценим здоровую и творческую рабочую атмосферу в нашей компании, что способствует профессиональному и личностному росту наших сотрудников.",
            en: "We appreciate healthy and creative work environment in our Company thus promoting professional and personal growth of our employees.",
          },
          {
            hy: "Մեր անձնակազմի ամուր և միասնական մնալու գրավականը փոխադարձ նվիրվածությունն է:",
            ru: "Преданность- ключ к нашим достижениям.",
            en: "Commitment is the key of our achievements.",
          },
          {
            hy: "Մենք սիրում ենք մեր գործը, և մեզ համար բարի համբավը ավելի մեծ կապիտալ է, քան ֆինանսները:",
            ru: "Мы любим то, что делаем, и наша слава более важный ресурс для нас, чем финансы.",
            en: "We love what we do, and our fame is more important resource for us, than finance.",
          },
        ],
      },
      cta: {
        main: {
          hy: "Եթե Ձեզ հետքրքրեց մեր ծառայությունները",
          ru: "Если вас интересуют наши услуги",
          en: "If you are interested in our services",
        },
        button: {
          hy: "Զանգահարեք",
          ru: "Позвоните нам",
          en: "Call us",
        },
      },
      projects: {
        title: {
          hy: "Մեր Նախագծերը",
          ru: "Наши проекты",
          en: "Our Projects",
        },
        subtitle: {
          hy: "Հիմնական Ծառայություններ",
          ru: "Основные Сервисы",
          en: "Main Services",
        },
      },
      news: {
        title: {
          hy: "Նորություններ",
          ru: "Новости",
          en: "News",
        },
        subtitle: {
          hy: "Հիմնական Ծառայություններ",
          ru: "Основные Сервисы",
          en: "Main Services",
        },
      },
      clients: {
        title: {
          hy: "Հաճախորդներ",
          ru: "Клиенты",
          en: "Clients",
        },
        subtitle: {
          hy: "Հիմնական Ծառայություններ",
          ru: "Основные Сервисы",
          en: "Main Services",
        },
      },
      ourOption: {
        title: {
          hy: "Կարծիք մեր Մասին",
          ru: "Отзывы о нас",
          en: "Opinion about us",
        },
        subtitle: {
          hy: "Հիմնական Ծառայություններ",
          ru: "Основные Сервисы",
          en: "Main Services",
        },
      },
    },
    aboutUs: {
      history: {
        hy: "Պատմությունը",
        ru: "История",
        en: "History",
      },
      mission: {
        hy: "Առաքելությունը",
        ru: "Наша миссия",
        en: "Our mission",
      },
      license: {
        hy: "Լիցենզիաներ",
        ru: "Лицензии",
        en: "Licenses",
      },
      team: {
        hy: "Մեր թիմը",
        ru: "Наша команда",
        en: "Team",
      },
      partners: {
        hy: "Գործընկերներ",
        ru: "Партнеры",
        en: "Partners",
      },
    },
    months: {
      hy: [
        "ՀՈՒՆ",
        "ՓԵՏ",
        "ՄԱՐՏ",
        "ԱՊՐ",
        "ՄԱՅ",
        "ՀՈՒՆ",
        "ՀՈՒԼ",
        "ՕԳՍ",
        "ՍԵՊ",
        "ՀՈԿ",
        "ՆՈՅ",
        "ԴԵԿ",
      ],
      ru: [
        "ЯНВ",
        "ФЕВ",
        "МАР",
        "АПР",
        "МАЙ",
        "ИЮНЬ",
        "ИЮЛЬ",
        "АВГ",
        "СЕН",
        "ОКТ",
        "НОЯ",
        "ДЕК",
      ],
      en: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
    },
    footer: {
      social: {
        hy: "Մենք սոցիալական հարթակներում",
        ru: "Мы в социальных сетях",
        en: "We are on social platforms",
      },
    },
  },
};

export default config;
